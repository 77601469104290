import SeparatorLine from "../../../components/SeparatorLine";
import "./SecondSection.css";
const paragraphs = {
  paragraph1:
    "Arosoft es una empresa que tiene como finalidad, ofrecer una gama de herramientas tecnológicas de alta calidad y desempeño, con enfoque en el desarrollo de aplicaciones a la medida de las necesidades del cliente, bajo plataformas estables y sólidas.",
  paragraph2:
    "Nacimos con el propósito de ser un integrador de soluciones. Para nosotros, un integrador de soluciones es una plataforma tecnológica en la que todas las necesidades del cliente, pasan a través de un canal, y este canal, detrás de lo que el cliente ve, es el encargado de manejar otros canales, encauzando todas las soluciones en una sola vía.",
};

const solutionsIntegratorList = [
  "Seguridad de Procesos",
  "Solidez y respaldo",
  "Optimización del tiempo",
];

const SecondSection = () => {
  return (
    <div className="background-wrapper-second-section">
      <img
        src="https://arosoft.hn/cdn-images/home_page/second-section/desktop-solutions.jpg"
        alt="Computador de Soluciones de Arosoft"
        className="solutions-img"
      />
      <div className="solutions-text">
        <h2 className="solutions-title">Nos dedicamos al desarrollo de</h2>
        <h1 className="solutions-subtitle">herramientas integrales</h1>
        <SeparatorLine />
        <p className="solutions-paragraph">{paragraphs.paragraph1}</p>
        <p className="solutions-paragraph">{paragraphs.paragraph2}</p>
        <h3 className="list-title">Ventajas de un integrador de soluciones:</h3>
        <ul className="solutions-list">
          {solutionsIntegratorList.map((item, index) => (
            <li key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SecondSection;
