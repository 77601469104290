import "./MwContactUs.css";

const MwContactUs = () => {
  return (
    <div className="contact-wrapper">
      <div className="contact-card">
        <img
          src="https://arosoft.hn/cdn-images/maintenance/contactenos.jpg"
          alt="Contacto de Arosoft"
          className="contact-img"
        />
        <h1 className="mwcontact-title">Estamos listos para atenderle</h1>
        <p className="mwcontact-paragraph">
          Contáctenos para brindarle la mejor solución a sus necesidades
        </p>
        <button
          className="contact-us-button"
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=50495124111",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          Envíenos un mensaje
        </button>
        <button
          className="contact-us-button"
          onClick={() =>
            window.open(
              "mailto:soporte@arosoft.hn",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          Escríbenos un correo
        </button>
      </div>
    </div>
  );
};

export default MwContactUs;
