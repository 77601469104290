import "./HeroSection.css";
import { useNavigate } from "react-router-dom";
const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className="hero-background">
        <div className="images-content">
          <img
            className="hero-gif"
            src="https://arosoft.hn/cdn-images/home_page/hero_section/icons-animation-2.gif"
            alt="Arosoft Soluciones"
          />
          <img
            className="work-person"
            src="https://arosoft.hn/cdn-images/home_page/hero_section/hero-section-mobile-person.png"
            alt="Arosoft Equipo de Trabajo"
          />
          <img
            className="work-people"
            src="https://arosoft.hn/cdn-images/home_page/hero_section/Desktop_group_people.png"
            alt="Equipo de trabajo de Arosoft"
          />
        </div>
        <div className="information-content">
          <h2 className="hero-title">Convertimos sus ideas</h2>
          <h1 className="hero-subtitle">en soluciones</h1>
          <p className="hero-paragraph">
            Ofrecemos desarrollo de software, infraestructura tecnológica, y
            servicios empresariales para impulsar su crecimiento y nuestro
            compromiso es brindarle herramientas innovadoras que optimicen sus
            procesos y potencien su negocio.
          </p>
          <button
            className="hero-button"
            onClick={() => navigate("/contactenos")}
          >
            CONÓCENOS MÁS
          </button>
        </div>
    </div>
  );
};

export default HeroSection;
