import ServicesCard from "../../../components/ServicesCard";
import "./ServicesSection.css";
const services = {
  card1: {
    title: "Soporte Técnico y Equipo",
    frontDescription:
      "Ofrecemos soluciones completas para software, hardware y redes. Ofrecemos mantenimiento preventivo y correctivo, instalación y configuración de equipos, y asistencia remota para resolver problemas técnicos con rapidez.",
    backDescription: `
    Entre nuestros servicios de soporte técnico y equipo, ofrecemos:
      - Instalación y configuración de sistemas operativos, Office y software especializado.
      - Reparación y mantenimiento de computadoras e impresoras.
      - Configuración de redes, impresoras y equipos periféricos.
      - Diagnóstico y resolución de problemas de hardware y software.
    `,
  },
  card2: {
    title: "Desarrollo de Software Personalizado",
    frontDescription:
      "Desarrollamos soluciones a la medida de sus necesidades, desde sitios web hasta aplicaciones avanzadas. Utilizamos tecnologías modernas para optimizar procesos empresariales.",
    backDescription: `
    Algunos de nuestros servicios de desarrollo de software incluyen:
      - Diseño y desarrollo de sitios web modernos y funcionales.
      - Aplicaciones web personalizadas según las necesidades de su empresa.
      - CRM preconfigurado para la gestión eficiente de clientes y procesos.
      - Software adaptado a industrias específicas, incluyendo ERP y herramientas internas.
    `,
  },
  card3: {
    title: "Soluciones Empresariales",
    frontDescription:
      "Maximice el potencial de su negocio con nuestras herramientas empresariales diseñadas para mejorar la eficiencia operativa y el control organizacional.",
    backDescription: `
    Como parte de nuestras soluciones empresariales, ofrecemos:
      - TAMS ERP: Sistema modular con funcionalidades para contabilidad, inventarios, producción y más.
      - Implementación de Microsoft 365, redes MESH y NAS.
      - Recuperación de datos y venta de equipos.
      - Instalación y configuración de sistemas de seguridad como cámaras, alarmas y biométricos.
    `,
  },
};

const ServicesSection = () => {
  return (
    <div className="background-services">
      <div className="services-wrapper">
        <h2 className="services-title">Conozca nuestros servicios y productos</h2>
        <h1 className="services-subtitle">Soluciones tecnológicas desarrolladas a su medida</h1>
        <div className="services-cards-section">
          {Object.values(services).map((service, index) => (
            <ServicesCard
              key={index}
              title={service.title}
              frontDescription={service.frontDescription}
              backDescription={service.backDescription}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
