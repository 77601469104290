import "./ClientsSection.css";

const clients = [
  "https://arosoft.hn/cdn-images/home_page/clients_section/logo_balresa.png",
  "https://arosoft.hn/cdn-images/home_page/clients_section/logo_famema.png",
  "https://arosoft.hn/cdn-images/home_page/clients_section/logo_rym.png",
  "https://arosoft.hn/cdn-images/home_page/clients_section/logo_super_barato.png",
  "https://arosoft.hn/cdn-images/home_page/clients_section/logo_uth.png",
  "https://arosoft.hn/cdn-images/home_page/clients_section/supermercado_el_exito.png"
];

const ClientsSection = () => {
  return (
    <div className="clients-section">
      <h2 className="clients-title">Parte de nuestros clientes</h2>
      <div className="clients-container">
        <div className="clients-track">
          {[...clients, ...clients].map((logo, index) => (
            <div key={index} className="client-card">
              <img src={logo} alt={`Cliente ${index + 1}`} className="client-logo" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsSection;
