import { useState } from "react";
import "./ServicesCard.css";
import ReactCardFlip from "react-card-flip";

interface ServicesCardProps {
  title: string;
  frontDescription: string;
  backDescription: string;
}

const ServicesCard = ({
  title,
  frontDescription,
  backDescription,
}: ServicesCardProps) => {
  const [flip, setFlip] = useState(false);

  return (
    <div className="services-wrapper">
      <ReactCardFlip
        isFlipped={flip}
        flipDirection="horizontal"
        flipSpeedBackToFront={0.5}
        flipSpeedFrontToBack={0.5}
      >
        <div className="services-card">
          <h2 className="card-title">{title}</h2>
          <p className="card-description">{frontDescription}</p>
          <button
            className="card-button material-symbols-outlined arrow-button-forward"
            onClick={() => setFlip(!flip)}
          >
            arrow_forward_ios
          </button>
        </div>
        <div className="services-card">
          <p className="card-description back-description">
            {" "}
            {backDescription.split("\n").map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </p>
          <button
            className="card-button material-symbols-outlined arrow-button-back"
            onClick={() => setFlip(!flip)}
          >
            arrow_back_ios
          </button>
          {/**  <button className="more-button">
            CONOZCA
            MÁS
          </button>*/}
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default ServicesCard;
