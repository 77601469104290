import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import About from "./pages/About";


function App() {
  return (
    <Router>
            <Routes>
                <Route path="/servicios" element={<Services />} />
                <Route path="/contactenos" element={<Contact />} />
                <Route path="/empresa" element={<About />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </Router>
  );
}

export default App;
