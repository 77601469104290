import "./ContactSection.css";
import { useNavigate } from "react-router-dom";

const ContactSection = () => {
  const navigate = useNavigate();
  return (
    <div className="contact-section-container">
      <div className="contact-section">
        <h1 className="contact-title">
          Tecnología a tu medida, soluciones que funcionan. Contáctanos hoy
        </h1>
        <button className="contact-button" onClick={() => navigate("/contactenos")}>Contáctenos
        </button>
      </div>
    </div>
  );
};

export default ContactSection;
