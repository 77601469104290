import ClientsSection from "../sections/home_sections/clients-section/ClientsSection";
import ContactSection from "../sections/home_sections/contact-section/ContactSection";
import HeroSection from "../sections/home_sections/hero-section/HeroSection";
import SecondSection from "../sections/home_sections/second-section/SecondSection";
import ServicesSection from "../sections/home_sections/services-section/ServicesSection";
import ThirdSection from "../sections/home_sections/third-section/ThirdSection";

const Home = () => {
  return (
    <>
      <HeroSection />
      <SecondSection />
      <ThirdSection />
      <ServicesSection />
      <ClientsSection />
      <ContactSection />
    </>
  );
};

export default Home;
