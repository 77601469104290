import "./SeparatorLine.css";

const SeparatorLine = () => {
  return (
    <div className="separator-line">
      <div className="first-line"></div>
      <div className="second-line"></div>
    </div>
  );
};

export default SeparatorLine;
