import MwContactUs from '../components/MwContactUs'

const Contact = () => {
  return (
    <div>
      <MwContactUs />
    </div>
  )
}

export default Contact
