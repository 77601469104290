import Maintenancepage from "../components/Maintenancepage";
const About = () => {
  return (
    <>
      <Maintenancepage />
    </>
  );
};

export default About;
