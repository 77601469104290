import "./Maintenancepage.css";

const Maintenancepage = () => {
  return (
    <div className="background-maintenance">
      <h1 className="maint-title">Esta página está en fase de desarrollo</h1>
      <h2 className="maint-subtitle">pero nosotros estamos listos para atenderle</h2>
    </div>
  )
}

export default Maintenancepage
