import "../assets/styles/footer_styles.css";
import logo from "../assets/images/arosoft_header.png";

export default function Footer() {
  return (
    <footer>
      <div className="footer-wrapper">
        <div className="column-wrapper">
          <div className="column-1">
            <a href="/">
              <img src={logo} height="35px" alt="Arosoft S de R.L logo" />
            </a>
            <p>
              +504 9512-4111
              <br />
              soporte@arosoft.hn
              <br />
              Barrio Los Andes, Edificio C&G, local #2, contiguo a
              Edificio Katia Reyes, 8 y 9 calle, 10 avenida, San Pedro Sula, Cortés,
              Honduras.
              <br />
            </p>
          </div>
          <div className="column-2">
            <a href="/empresa">EMPRESA</a>
            <br />
            <a href="/servicios">SERVICIOS</a>
            <br />
            <a href="/contactenos">CONTACTO</a>
            <br />
          </div>
          <div className="column-3">
            <p>Contáctenos</p>
            <div className="icons-container">
              <a href="mailto:soporte@arosoft.hn" className="icons-fill-f">
                <svg viewBox="0 0 512 512" fill="white">
                  <path d="M464 80H48a16 16 0 00-16 16v320a16 16 0 0016 16h416a16 16 0 0016-16V96a16 16 0 00-16-16zM265.82 284.63a16 16 0 01-19.64 0L89.55 162.81l19.64-25.26L256 251.73l146.81-114.18 19.64 25.26z" />
                </svg>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=50495124111"
                target="_blank"
                rel="noreferrer"
                className="icons-fill-f"
              >
                <svg viewBox="0 0 24 24" fill="white" width="1em">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M2.004 22l1.352-4.968A9.954 9.954 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 01-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 00-.371.1 1.293 1.293 0 00-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 006.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 003.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 00.833-.231 4.83 4.83 0 00.383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 00-.177-.041.482.482 0 00-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 01-.368.13 1.416 1.416 0 01-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 01-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 01-1.02-1.268l-.059-.095a.923.923 0 01-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 00.263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 00-.403.004z" />
                </svg>
              </a>
              <a
                href="tel:50495124111"
                target="_blank"
                className="icons-fill-f"
                rel="noreferrer"
              >
                <svg fill="white" viewBox="0 0 16 16" width="1em">
                  <path d="M3 2a2 2 0 012-2h6a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V2zm6 11a1 1 0 10-2 0 1 1 0 002 0z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
