import SeparatorLine from "../../../components/SeparatorLine";
import "./ThirdSection.css";

const paragraphs = {
  paragraph1:
    "Hemos entendido, que su necesidad no es solamente contar con un sistema de información, sino que su proveedor de servicios debe de entender cuál es su problema, y así ayudarle a implementar herramientas que SI FUNCIONEN y resuelvan todas sus necesidades.",
  paragraph2:
    "Nos ponemos en su piel, nos ponemos sus zapatos, nos involucramos como si formamos parte de su organización,y así visualizamos lo que el cliente realmente necesita y que soluciona su problema.",
};
const ThirdSection = () => {
  return (
    <div className="background-wrapper-third-section">
      <div className="text-section">
        <h2 className="title">¿Por qué somos diferentes?</h2>
        <h1 className="subtitle">
          No visualizamos la data a ingresar, proyectamos los resultados a
          obtener.
        </h1>
        <SeparatorLine />
        <p className="paragraph">{paragraphs.paragraph1}</p>
        <p className="paragraph">{paragraphs.paragraph2}</p>
      </div>
      <img
        src="https://arosoft.hn/cdn-images/home_page/third_section/businessman2.jpg"
        alt="Computador de Soluciones de Arosoft"
        className="businessman-img"
      />
    </div>
  );
};

export default ThirdSection;
