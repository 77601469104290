import Maintenancepage from '../components/Maintenancepage'

const Services = () => {
  return (
    <div>
      <Maintenancepage />
    </div>
  )
}

export default Services
